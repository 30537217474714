import { Avatar, Box, Grid, Typography } from "@mui/material";
import React from "react";
import { appIcons } from "../../../assets";
import { useNavigate } from "react-router-dom";
import "./style.css";

const cardData = [
  {
    Icon: appIcons?.instagramOutline,
    colorIcon: appIcons?.instagramColorIcon,
    header: "Instagram Giveaway",
    title: "Select a winner at random from your photos or videos.",
    to: "instagram-giveaway",
    status: "",
    backgroundColor:
      "linear-gradient(270deg,#ffda8d,#e62a21 48.81%,#8121a3 106.95%)",
  },
  {
    Icon: appIcons?.facebookOutline,
    colorIcon: appIcons?.fbColorIcon,
    header: "Facebook Giveaway",
    title: "Select a winner at random from your posts.",
    to: "facebook-giveaway",
    status: "",
    backgroundColor: "#3a559f",
  },
  {
    Icon: appIcons?.youtubeOutline,
    colorIcon: appIcons?.youtubeColorIcon,
    header: "Youtube Giveaway",
    title: "Select a winner at random from your videos.",
    to: "youtube-giveaway",
    status: "",
    backgroundColor: "red",
  },
  {
    Icon: appIcons?.tiktokOutline,
    colorIcon: appIcons?.tikTokColorIcon,
    header: "Tiktok Giveaway",
    title: "Select a winner at random from your Tiktok video comments.",
    to: "tiktok-giveaway",
    status: "",
    backgroundColor: "#24ece7",
  },
  {
    Icon: appIcons?.twiterOutline,
    colorIcon: appIcons?.twitterColorIcon,
    header: "Twitter Giveaway",
    title: "Select a winner at random from your tweet and followers.",
    to: "twitter-giveaway",
    status: "",
    backgroundColor: "#000000",
  },
];

function SocialCards() {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2} mt={3}>
      {cardData?.map(
        ({ Icon, header, title, to, status, backgroundColor, colorIcon }) => {
          return (
            <Grid item xs={12} sm={4} md={3} lg={2.4}>
              <Box
                className={"card-background"}
                sx={{
                  background: backgroundColor,
                }}
              >
                <Box
                  border={"1px solid #191A1C"}
                  p={2}
                  borderRadius={"15px"}
                  height={"250px"}
                  position={"relative"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate(`/${to}`)}
                  className={"social-card-container"}
                  bgColor={"#fff"}
                  zIndex={1}
                >
                  <Box height={35} width={35}>
                    <img src={Icon} width={"90%"} className="icon-normal" />
                    <img src={colorIcon} width={"90%"} className="icon-color" />
                  </Box>
                  <Typography
                    variant={"h3"}
                    fontFamily={"Roboto Flex"}
                    fontSize={18}
                    color={"#161616"}
                    fontWeight={600}
                    m={"20px 0 10px 0"}
                  >
                    {header}
                  </Typography>
                  <Typography
                    variant={"h3"}
                    fontFamily={"Roboto Flex"}
                    fontSize={15}
                    fontWeight={400}
                    m={"10px 0 5px 0"}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant={"h4"}
                    fontFamily={"Roboto Flex"}
                    fontSize={13}
                    fontWeight={400}
                    color={"red"}
                  >
                    {status}
                  </Typography>
                  <Avatar
                    src={appIcons.eastArrow}
                    sx={{
                      border: "1px solid #000",
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      cursor: "pointer",
                      "& .MuiAvatar-img": {
                        width: "21px",
                        height: "auto",
                      },
                    }}
                  />
                  {/* <Box
                    sx={{
                      border: "1px solid #000",
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      cursor: "pointer",
                      height: 36,
                      width: 36,
                      borderRadius: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      transition: "0.1s",
                    }}

                    className={"arrow-container"}
                  >
                    <img
                      src={appIcons.eastArrow}
                      width={"21px"}
                      height={"auto"}
                      className="first-arrow-1"
                    />
                  </Box> */}
                </Box>
              </Box>
            </Grid>
          );
        }
      )}
    </Grid>
  );
}

export default SocialCards;
